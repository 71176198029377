.nav-pp {
    top: 472px;
    left: 50px;
    width: auto;
    height: 65px;
    background: var(--gris-auxiliar) 0% 0% no-repeat padding-box;
    background: #E6E6E6 0% 0% no-repeat padding-box;
    opacity: 1;
}

.text {
    top: 494px;
    left: 568px;
    width: 80px;
    height: 25px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-27) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--deep-blue);
    text-align: left;
    font: normal normal medium 18px/27px Poppins;
    letter-spacing: 0px;
    color: #091926;
    opacity: 1;
}

.login {
    top: 487px;
    left: 949px;
    width: 164px;
    height: 40px;
    background: var(--green-field) 0% 0% no-repeat padding-box;
    background: #B8D943 0% 0% no-repeat padding-box;
    opacity: 1;
}

.login-text {
    top: 496px;
    left: 969px;
    width: 124px;
    height: 22px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-fira-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--deep-blue);
    text-align: right;
    font: normal normal 600 18px/22px Fira Sans;
    letter-spacing: 0px;
    color: #091926;
    text-transform: uppercase;
    opacity: 1;
}